import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HouseItem from "../House/item";
import { House } from "../../entities/house";
import NextIcon from "@mui/icons-material/NavigateNextRounded";
import BeforeIcon from "@mui/icons-material/NavigateBeforeRounded";

interface IQueryImovelSlick {
  index: number;
  houses: House[];
}

function NextArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <NextIcon
      className={className}
      onClick={onClick}
      {...style}
      sx={{
        color: "silver",
        //border: "1px solid silver",
        p: 1,
        borderRadius: "50%",
        "&:hover": {
          backgroundColor: "silver",
          color: "white",
        },
      }}
    />
  );
}

function BeforeArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <BeforeIcon
      className={className}
      onClick={onClick}
      {...style}
      sx={{
        color: "silver",
        //border: "1px solid white",
        p: 1,
        borderRadius: "50%",
        "&:hover": {
          backgroundColor: "silver",
          color: "white",
        },
      }}
    />
  );
}

export default function ImovelSlick({ index, houses }: IQueryImovelSlick) {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    nextArrow: <NextArrow />,
    prevArrow: <BeforeArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Slider key={`slider${index}`} {...settings}>
      {houses.map((item: House) => (
        <HouseItem key={`sliderItem${index}${item.COD_IMOVEL}`} index={index} house={item} />
      ))}
    </Slider>
  );
}

import React from "react";
import {
  Box,
  Stack,
  Slide,
  Fab,
} from "@mui/material";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import HouseBloc from "../../components/House/bloc";
import Loading from "../../components/Loading/loading";
import useHouses from "../../hooks/useHouses";
import HomeSlick from "../../components/HomeSlick";
import useLocalStorage from "../../hooks/useLocalStorage";
import SimpleSearch from "../../components/Search/SimpleSearch";
import { green } from "@mui/material/colors";

const HomePage = function HomePage(): JSX.Element {
  const [animationState, setAnimationState] = React.useState(false);
  const [company,] = useLocalStorage("imobios:company", { COD_EMPRESA: -1 });

  const { data: houseContrast, loading: loadHouseContrast } = useHouses({ contrast: true });
  const { data: houseRecent, loading: loadHouseRecent } = useHouses({ recent: true });

  React.useEffect(() => {
    setAnimationState(true);
    return () => setAnimationState(false);
  }, []);

  return (
    <Slide direction="up" in={animationState} mountOnEnter unmountOnExit>
      <Box
        sx={{
          width: "100%",
          margin: "0 auto",
          mt: { xs: 0, sm: 0 },
        }}
      >
        <Stack
          direction={"column"}
          spacing={2}
          justifyContent="space-between"
          sx={{
            width: { xs: "100%", sm: "100%" },
            py: { sm: 2, xs: 2 },
            px: { sm: 0, xs: 0 },
          }}
        >
          <HomeSlick index={1} images={company.images} />
          <SimpleSearch model="rent" type="" cityName="" />
          <HouseBloc index={1} title="Imóveis em Destaque" houses={houseContrast} />
          <HouseBloc index={2} title="Recém Adicionados" houses={houseRecent} />
        </Stack>
        <Loading loading={loadHouseContrast || loadHouseRecent} />
      </Box>
    </Slide>
  );
};
export default HomePage;

import * as React from "react";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

interface IQueryTypeHouse {
  initialValue: string | null | undefined;
  onChange: (newValue: string | null) => void;
}

export default function TypeHouse({ initialValue, onChange }: IQueryTypeHouse) {
  const [value, setValue] = React.useState<string | null | undefined>(initialValue);

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string | null
  ) => {
    setValue(newValue);
    if (onChange !== null) {
      onChange(newValue);
    }
  };

  return (
    <ToggleButtonGroup
      color="primary"
      value={value}
      exclusive
      onChange={handleAlignment}
      aria-label="tipo imovel"
      size="small"
    >
      <ToggleButton value="R" aria-label="left aligned" color="secondary">
        Residencial
      </ToggleButton>
      <ToggleButton value="C" aria-label="centered" color="secondary">
        Comercial
      </ToggleButton>
      <ToggleButton value="T" aria-label="centered" color="secondary">
        Terrenos
      </ToggleButton>
      <ToggleButton value="U" aria-label="centered" color="secondary">
        Rural
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

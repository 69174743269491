import axios from "axios";

const instance = axios.create({
    baseURL: process.env.REACT_APP_API,
    //baseURL: "https://menuchef.com.br/api/versatile",
    timeout: 10000,
});

async function get(path: string, options?: any) {
    return await instance.get(path, options);
}

async function post(path: string, data: any) {
    return await instance.post(path, data);
}

async function getCompany() {
    return await get("/company");
}

async function getCompanyImages({ companyId }: any) {
    return await get(`/company/${companyId}/images`);
}

async function getHouses() {
    return await get("/house");
}

async function getHousesQuery({ model, type, city,
    minPrice, maxPrice, minArea, maxArea, bedrooms, suites, garages, order }: any) {
    const params: any = {};
    if (model !== undefined && model !== "") {
        params.model = model;
    }
    if (type !== undefined && type !== "") {
        params.type = type;
    }
    if (city !== undefined && city !== "") {
        params.city = city;
    }
    if (minPrice !== undefined && minPrice !== null) {
        params.minPrice = minPrice;
    }
    if (maxPrice !== undefined && maxPrice !== null) {
        params.maxPrice = maxPrice;
    }
    if (minArea !== undefined && minArea !== null) {
        params.minArea = minArea;
    }
    if (maxArea !== undefined && maxArea !== null) {
        params.maxArea = maxArea;
    }
    if (bedrooms !== undefined && bedrooms !== null) {
        params.bedrooms = bedrooms;
    }
    if (suites !== undefined && suites !== null) {
        params.suites = suites;
    }
    if (garages !== undefined && garages !== null) {
        params.garages = garages;
    }
    if (order !== undefined && order !== null) {
        params.order = order;
    }
    return await get(`/house`, { params: params });
}

async function getHouse(id: number) {
    if (id !== undefined && id > 0) {
        return await get(`/house/${id}`);
    }
    return await get("/house");
}

async function getHouseContrast() {
    return await get("/house?contrast=true");
}

async function getHouseRecent() {
    return await get("/house?recent=true");
}

async function getHouseImages({ houseId }: any) {
    return await get(`/house/${houseId}/images`);
}

const methods = {
    post,
    getCompany,
    getCompanyImages,
    getHouses,
    getHousesQuery,
    getHouse,
    getHouseContrast,
    getHouseRecent,
    getHouseImages,
}

export default methods;

import React from 'react';
import { Company } from '../entities/company';
import api from '../services/api';
import useLocalStorage from './useLocalStorage';

interface IQueryCompany {
    reload?: boolean | undefined;
}

const useCompany = ({ reload }: IQueryCompany) => {
    const [company, setCompany] = useLocalStorage("imobios:company", { COD_EMPRESA: -1 });
    const [data, setData] = React.useState<Company>({ COD_EMPRESA: -1 });
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState();

    const processData = async (data: Company) => {
        //console.log(data);
        try {
            if (data.COD_EMPRESA > 0) {
                await api.getCompanyImages({ companyId: data.COD_EMPRESA })
                    .then((res) => data.images = res.data.data);
            }
            setData(data);
            setCompany(data);
        } finally {
            setLoading(false);
        }
    }

    const processError = (err: any) => {
        console.log(err);
        setError(err);
        setLoading(false);
    }

    const exec = () => {
        const search = reload || true;
        if (!search && company.COD_EMPRESA > 0) {
            setData(company);
            return;
        }
        setData({ COD_EMPRESA: -1 });
        setError(undefined);
        setLoading(true);

        api.getCompany()
            .then((res) => processData(res.data.data[0]))
            .catch((err) => processError(err));
    }

    React.useEffect(() => {
        exec();
    }, []);

    return {
        data,
        loading,
        error,
    }
}

export default useCompany;
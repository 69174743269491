import { createTheme, Theme } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import { useEffect, useState } from "react";
import { Company } from "../entities/company";
import useCompany from "../hooks/useCompany";
import useLocalStorage from "../hooks/useLocalStorage";
import lightTheme from "./light.theme";

const useApiTheme = (company: Company) => {
  //const { data: company } = useCompany();
  //const [company,] = useLocalStorage("imobios:company", { COD_EMPRESA: -1 });
  const [theme, setTheme] = useState<Theme>(lightTheme);

  useEffect(() => {
    if (company.COD_EMPRESA <= 0) {
      return;
    }
    const myTheme = createTheme({
      palette: {
        primary: {
          //main: "#2d3f60",
          main: company.SITE_COR_PRIMARIO || "#2d3f60",
        },
        secondary: {
          //main: "#c04259",
          main: company.SITE_COR_SECUNDARIO || "#c86253",
        },
        text: {
          primary: "#1a1a1a",
          secondary: "#252525",
        },
        background: {
          paper: "#fafafa",
        },
      },
      typography: {
        //fontFamily: "Montserrat",
        fontFamily: "Roboto",
      },
      components: {
        MuiTextField: {
          styleOverrides: {
            root: {
              borderColor: blueGrey[500],
              "input:valid + fieldset": {
                borderColor: blueGrey[600],
              },
              "&:hover": {
                backgroundColor: "transparent",
              },
              "&.Mui-focused": {
                backgroundColor: "transparent",
                borderColor: blueGrey[500],
              },
            },
          },
        },
        MuiAppBar: {
          styleOverrides: {
            root: {
              boxShadow: "none",
              color: "252525",
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            containedPrimary: {
              color: "white",
            },
          },
        },
        MuiDrawer: {
          styleOverrides: {
            paper: {
              backgroundColor: `#689F38`,
              color: "#f1f1f1",
              border: "none",
            },
          },
        },
      },
      transitions: {
        duration: {
          shortest: 150,
          shorter: 200,
          short: 250,
          // most basic recommended timing
          standard: 300,
          // this is to be used in complex animations
          complex: 375,
          // recommended when something is entering screen
          enteringScreen: 225,
          // recommended when something is leaving screen
          leavingScreen: 195,
        },
      },
    });
    setTheme(myTheme);
  }, [company]);

  return { theme };
}

export default useApiTheme;
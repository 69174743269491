import { Box, Paper, Stack, Typography, Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { House } from "../../entities/house";
import numbers from "../../functions/numbers";
import IconDetails from "../IconDetails/icondetails";
import HouseImageItem from "./houseImage";

interface IQueryHouse {
    index: number;
    house: House;
}

export default function HouseItem({ index, house }: IQueryHouse) {
    const navigate = useNavigate();

    return (
        <Box
            component={Paper}
            sx={{
                backgroundColor: "white",
                display: "flex",
                flexDirection: "row",
                justifyContent: "initial",
                alignItems: "initial",
                height: {
                    xs: "600px",
                    sm: "500px",
                },
                width: {
                    xs: "99%",
                    sm: "95%",
                },
                margin: "2px",
            }}
        >
            <Stack
                direction="column"
                justifyContent="space-between"
                alignContent="initial"
                sx={{ height: "100%", width: "100%", margin: "0 auto" }}
            >
                <Link to={`/house/${house.COD_IMOVEL}`} style={{ textDecoration: 'none' }}>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            height: {
                                xs: "300px",
                                sm: "200px",
                            },
                            width: "100%",
                            backgroundColor: "silver",
                        }}
                        alignItems={"center"}
                        justifyContent={"center"}
                    >
                        <HouseImageItem
                            index={index}
                            loading={false}
                            house={house}
                        />
                    </Box>
                </Link>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        margin: "10px",
                        flexGrow: 1,
                        //mr: 1,
                    }}
                //alignItems={"center"}
                //justifyContent={"space-between"}
                >
                    <Typography variant="overline">
                        {house.CIDADE || "Cidade"}
                    </Typography>
                    <Typography variant="h6" color="primary.main">
                        {house.BAIRRO || "Bairro"}
                    </Typography>
                    <IconDetails center={true} house={house} />
                    <Typography variant="body2">{`${house.ENDERECO}, ${house.NUMERO}`}</Typography>
                    <Typography variant="button">
                        {(house.VL_ALUGUEL || 0) > 0
                            ? `Aluguel por R$ ${numbers.toString(
                                house.VL_ALUGUEL
                            )}`
                            : ``}
                    </Typography>
                    <Typography variant="button">
                        {(house.VL_VENDA || 0) > 0
                            ? `Venda por R$ ${numbers.toString(house.VL_VENDA)}`
                            : ``}
                    </Typography>
                </Box>
                <Link to={`/house/${house.COD_IMOVEL}`}
                    style={{ textDecoration: 'none', paddingRight: '30px' }}>
                    <Button
                        /* variant="contained" */
                        sx={{ margin: 2 }}
                        onClick={() => {
                            navigate(`/house/${house.COD_IMOVEL}`)
                        }}
                        fullWidth
                    >
                        Ver Detalhes
                    </Button>
                </Link>
            </Stack>
        </Box>
    );
}

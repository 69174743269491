import {
    Box,
    Button,
    Paper,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import KeyboardDoubleArrowRightOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowRightOutlined";
import ListHouseImage from "../ListHouseImage";
import { House } from "../../entities/house";
import numbers from "../../functions/numbers";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import { Link, useNavigate } from "react-router-dom";
import IconDetails from "../IconDetails/icondetails";

interface IQueryListHouse {
    loading: boolean;
    houses: House[];
}

export default function ListHouse({ loading, houses }: IQueryListHouse) {
    const navigate = useNavigate();

    if (!houses || houses === null || loading) {
        return <Typography>Carregando...</Typography>;
    }
    if (houses.length <= 0) {
        return (
            <Typography>
                Nenhum registro encontrado para sua busca...
            </Typography>
        );
    }
    return (
        <>
            {houses.map((house) => {
                return (
                    <Box
                        key={`listHouse${house.COD_IMOVEL}`}
                        component={Paper}
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            margin: "0 auto",
                            marginBottom: 2,
                            /* mt: { xs: 0, sm: 0 }, */
                            backgroundColor: "white",
                        }}
                    >
                        <Stack
                            direction={{
                                xs: "column",
                                sm: "row",
                                /* md: "row", */
                            }}
                            spacing={2}
                            sx={{
                                width: { xs: "100%", sm: "100%" },
                                py: { sm: 0, xs: 0 },
                                px: { sm: 0, xs: 0 },
                                margin: "0 auto",
                            }}
                        >
                            <Box
                                sx={{
                                    height: { xs: "300px", sm: "200px" },
                                    width: { xs: "100%", sm: "300px" },
                                }}
                            >
                                <ListHouseImage images={house.images} />
                            </Box>
                            <Stack
                                direction={{
                                    xs: "column",
                                    sm: "row",
                                    /* md: "row", */
                                }}
                                spacing={2}
                                sx={{
                                    width: { xs: "95%", sm: "100%" },
                                    py: { sm: 2, xs: 2 },
                                    px: { sm: 2, xs: 2 },
                                    margin: "0 auto",
                                }}
                            >
                                <Box
                                    sx={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "start",
                                    }}
                                >
                                    <Typography variant="body1">
                                        {house.CIDADE}
                                    </Typography>
                                    <Typography
                                        variant="h6"
                                        color="primary.main"
                                    >
                                        {house.BAIRRO}
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        {`${house.ENDERECO}, ${house.NUMERO}`}
                                    </Typography>

                                    <IconDetails
                                        key={4}
                                        center={false}
                                        house={house}
                                    />

                                    {/*<Typography
                                        variant="subtitle2"
                                        align="center"
                                        color="gray"
                                    >
                                        {house.DESCRICAO_DETALHADA || "-"}
                                </Typography>*/}
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: {
                                            sm: "flex-end",
                                            xs: "start",
                                        },
                                        width: "100%",
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        color="primary.main"
                                    >
                                        {(house.VL_ALUGUEL || 0) > 0 ? (
                                            `Aluguel R$ ${numbers.toString(
                                                house.VL_ALUGUEL
                                            )}`
                                        ) : (
                                            <></>
                                        )}
                                    </Typography>
                                    <Typography
                                        variant="h6"
                                        color="primary.main"
                                    >
                                        {(house.VL_VENDA || 0) > 0 ? (
                                            `Venda R$ ${numbers.toString(
                                                house.VL_VENDA
                                            )}`
                                        ) : (
                                            <></>
                                        )}
                                    </Typography>

                                    {(house.VL_IPTU || 0) > 0 ? (
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "start",
                                                margin: "1px",
                                            }}
                                        >
                                            <ReceiptOutlinedIcon
                                                sx={{
                                                    color: "secondary",
                                                    "&:hover": {
                                                        color: "secondary.main",
                                                    },
                                                }}
                                                fontSize="small"
                                            />
                                            <Typography variant="body2">
                                                {`IPTU R$ ${numbers.toString(
                                                    house.VL_IPTU
                                                )}`}
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <></>
                                    )}

                                    {(house.VL_CONDOMINIO || 0) > 0 ? (
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "start",
                                                margin: "1px",
                                            }}
                                        >
                                            <MonetizationOnOutlinedIcon
                                                sx={{
                                                    color: "secondary",
                                                    "&:hover": {
                                                        color: "secondary.main",
                                                    },
                                                }}
                                                fontSize="small"
                                            />
                                            <Typography variant="body2">
                                                {`Condominio R$ ${numbers.toString(
                                                    house.VL_CONDOMINIO
                                                )}`}
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <></>
                                    )}
                                    <Link to={`/house/${house.COD_IMOVEL}`} style={{ textDecoration: 'none' }}>
                                        <Button
                                            onClick={() =>
                                                navigate(
                                                    `/house/${house.COD_IMOVEL}`
                                                )
                                            }
                                            variant="contained"
                                            endIcon={
                                                <KeyboardDoubleArrowRightOutlinedIcon />
                                            }
                                            sx={{
                                                color: "white",
                                                marginTop: 2,
                                            }}
                                            color="secondary"
                                        >
                                            Ver Detalhes
                                        </Button>
                                    </Link>
                                </Box>
                            </Stack>
                        </Stack>
                    </Box>
                );
            })}
        </>
    );
}

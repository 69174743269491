function render(condition: boolean, conditionShow: any, notConditionShow?: any): any {
    if (notConditionShow === undefined || notConditionShow === null) {
        notConditionShow = (<></>);
    }
    return condition ? conditionShow : notConditionShow;
}

const components = {
    render,
}

export default components;
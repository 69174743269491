import { Alert, Button, Snackbar, Stack, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import SendIcon from "@mui/icons-material/Send";
import { useForm } from "react-hook-form";
import methods from "../../services/api";
import { useState } from "react";
import Loading from "../Loading/loading";

const RequestContact = ({ id }: any) => {
    const { register, handleSubmit, reset } = useForm();
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState<string | undefined | null>();
    const [loading, setLoading] = useState(false);

    const Submit = (data: any) => {
        if (data.name === undefined || data.name === null || data.name === "") {
            setError("Nome obrigatório!");
            return;
        }
        if (data.email === undefined || data.email === null || data.email === "") {
            setError("E-mail obrigatório!");
            return;
        }

        setSuccess(false);
        setError(undefined);
        setLoading(true);
        data.id = Number(id);
        methods.post("/request-contact", data)
            .then((res) => {
                setSuccess(true);
                reset();
            })
            .catch((err) => setError(err.data.data.error))
            .finally(() => setLoading(false));

    };

    return (
        <>
            <Stack
                component={"form"}
                onSubmit={handleSubmit(Submit)}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "10px",
                }}
            >
                <Typography variant="h4" color="gray" align="center">
                    Solicitar Contato
                </Typography>

                <TextField
                    {...register("name")}
                    label="Seu nome"
                    variant="outlined"
                    size="small"
                    sx={{ marginTop: 2 }}
                />
                <TextField
                    {...register("email")}
                    label="Email"
                    variant="outlined"
                    size="small"
                    sx={{ marginTop: 2 }}
                />
                <TextField
                    {...register("phone")}
                    label="Telefone"
                    variant="outlined"
                    size="small"
                    sx={{ marginTop: 2 }}
                />
                <TextField
                    {...register("message")}
                    multiline
                    label="Mensagem"
                    variant="outlined"
                    sx={{ marginTop: 2 }}
                />

                <Box sx={{ alignItems: "center" }}>
                    <Button
                        type="submit"
                        variant="contained"
                        endIcon={<SendIcon />}
                        sx={{
                            marginTop: 2,
                            color: "white",
                        }}
                        color="secondary"
                        fullWidth
                    >
                        Enviar
                    </Button>
                </Box>
            </Stack>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={success}
                autoHideDuration={2000}>
                <Alert severity="success" sx={{ width: "100%" }}>
                    E-mail enviado com sucesso
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={error !== undefined && error !== null && error !== ""}
                autoHideDuration={3000}>
                <Alert severity="error" sx={{ width: "100%" }}>
                    {error}
                </Alert>
            </Snackbar>
            <Loading loading={loading} />
        </>
    );
}

export default RequestContact;
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CompanyImages } from "../../entities/company";
import { Box, Container, Stack } from "@mui/material";
import NextIcon from "@mui/icons-material/NavigateNextRounded";
import BeforeIcon from "@mui/icons-material/NavigateBeforeRounded";
import { useRef } from "react";

interface IQueryHomeSlick {
  index: number;
  images?: CompanyImages[];
}

export default function HomeSlick({ index, images }: IQueryHomeSlick) {
  const sliders = useRef<Slider>(null);

  if (!images || images === null || images.length <= 0) {
    return (<Box key={`homeSlick${index}`}></Box>);
  }

  function NextArrow() {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          justifyContent: "flex-end",
          marginRight: "6px",
        }}
      >
        <NextIcon
          onClick={() => sliders.current!.slickNext()}
          sx={{
            display: "flex",
            position: "absolute",
            float: "inline-end",
            top: { sm: "250px", xs: "150px" },
            color: "white",
            border: "1px solid white",
            p: 1,
            borderRadius: "50%",
            "&:hover": {
              backgroundColor: "white",
              color: "silver",
              cursor: "pointer",
            },
          }}
        />
      </Box>
    );
  }

  function BeforeArrow() {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          marginLeft: "6px",
        }}
      >
        <BeforeIcon
          onClick={() => sliders.current!.slickPrev()}
          sx={{
            display: "flex",
            position: "absolute",
            top: { sm: "250px", xs: "150px" },
            color: "white",
            border: "1px solid white",
            p: 1,
            borderRadius: "50%",
            "&:hover": {
              backgroundColor: "white",
              color: "silver",
              cursor: "pointer",
            },
          }}
        />
      </Box>
    );
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    nextArrow: <></>,
    prevArrow: <></>,
  };

  return (
    <Box
      sx={{ display: "block", flexDirection: "column" }}>
      <Slider
        key={`homeSlider${index}`}
        ref={sliders}
        {...settings}>
        {images.map((image: CompanyImages) => (
          <Box
            key={`homeImg${index}${image.COD_EMPRESA_ANEXO}`}
            sx={{
              display: "flex",
              flexDirection: "column",
              height: { sm: "400px", xs: "180px" },
              width: "xl",
              backgroundColor: "white",
            }}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <img
              src={image.IMAGEM}
              alt={`${index} - ${image.DESCRICAO}`}
              style={{ height: "100%", width: "100%" }}
            />
          </Box>
        ))}
      </Slider>
      {images.length > 1 ? (
        <>
          <BeforeArrow />
          <NextArrow />
          <Box sx={{ height: "20px" }} />
        </>
      ) : (<></>)}
    </Box>
  )
}

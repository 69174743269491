import * as React from "react";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

interface IQueryModelHouse {
  initialValue: string | null | undefined;
  onChange: (newValue: string) => void;
}

export default function ModelHouse({ initialValue, onChange }: IQueryModelHouse) {
  const [value, setValue] = React.useState<string>(initialValue || "rent");

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string
  ) => {
    setValue(newValue);
    if (onChange !== null) {
      onChange(newValue);
    }
  };

  return (
    <ToggleButtonGroup
      color="primary"
      value={value}
      exclusive
      onChange={handleAlignment}
      aria-label="o que precisa"
      size="small"
    >
      <ToggleButton value="purchase" aria-label="left aligned" color="secondary">
        Comprar
      </ToggleButton>
      <ToggleButton value="rent" aria-label="centered" color="secondary">
        Alugar
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

import {
    Box,
    Button,
    Paper,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "../../hooks/useLocalStorage";
import ModelHouse from "../ModelHouse";
import TypeHouse from "../TypeHouse";
import { useState } from "react";
import ToggleButtonsBedroom from "../SearchFilter/ToggleBedroom";
import ToggleGarage from "../SearchFilter/ToggleGarage";
import ToggleSuite from "../SearchFilter/ToggleSuite";
import RangePrice from "../SearchFilter/RangePrice";
import RangeArea from "../SearchFilter/RangeArea";
import ToggleOrder from "../SearchFilter/ToggleOrder";

interface ISimpleSearch {
    model: string | "rent" | "purchase";
    type?: string | null;
    cityName?: string | null;
}

const CompleteSearch = function CompleteSearch({
    model,
    type,
    cityName,
}: ISimpleSearch): JSX.Element {
    const navigate = useNavigate();
    const [company] = useLocalStorage("imobios:company", { COD_EMPRESA: -1 });
    const [modelHouse, setModelHouse] = useState<string | null>(model);
    const [typeHouse, setTypeHouse] = useState<string | null | undefined>(type);
    const [minPrice, setMinPrice] = useState<string | null | undefined>();
    const [maxPrice, setMaxPrice] = useState<string | null | undefined>();
    const [minArea, setMinArea] = useState<string | null | undefined>();
    const [maxArea, setMaxArea] = useState<string | null | undefined>();
    const [bedrooms, setBedrooms] = useState<string | null | undefined>();
    const [suites, setSuites] = useState<string | null | undefined>();
    const [garages, setGarages] = useState<string | null | undefined>();
    const [order, setOrder] = useState<string | null | undefined>();
    const [city, setCity] = useState(cityName);

    return (
        <Box
            component={Paper}
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px",
            }}
        >
            <Stack
                direction={"column"}
                spacing={2}
                justifyContent="space-around"
                alignContent="center"
                sx={{
                    width: { xs: "100%", sm: "100%" },
                    py: { sm: 2, xs: 2 },
                    px: { sm: 2, xs: 2 },
                }}
            >
                <Stack
                    direction="row"
                    justifyContent="space-around"
                    alignContent="center"
                    sx={{ width: "100%", margin: "0 auto" }}
                >
                    <Typography variant="h4">
                        {company.NOME_FANTASIA || "Não encontrado..."}
                    </Typography>
                </Stack>
                <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={2}
                    justifyContent="space-between"
                    alignContent="center"
                    sx={{ width: "100%", margin: "0 auto" }}
                >
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography>O que você precisa?</Typography>
                        <ModelHouse
                            initialValue={modelHouse}
                            onChange={(newValue) => setModelHouse(newValue)}
                        />
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography>Tipos de Imóveis</Typography>
                        <TypeHouse
                            initialValue={typeHouse}
                            onChange={(newValue) => setTypeHouse(newValue)}
                        />
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography>Localidade</Typography>
                        <TextField
                            value={city || ""}
                            variant="outlined"
                            size="small"
                            placeholder="Cidade ou Bairro"
                            onChange={(e) => setCity(e.target.value)}
                        />
                    </Box>
                    <ToggleOrder
                        initialValue={order}
                        onChange={(newValue) => setOrder(newValue)} />
                </Stack>
                <Box sx={{
                    display: "flex",
                    flexDirection: { sm: "row", xs: "column" },
                    width: "100%", margin: "0 auto",
                    alignContent: "center"
                }}>

                </Box>

                <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={2}
                    justifyContent="space-evenly"
                    alignContent="center"
                    sx={{ width: "100%", margin: "0 auto" }}
                >

                    <RangePrice min={minPrice} setMin={setMinPrice} max={maxPrice} setMax={setMaxPrice} />
                    <RangeArea min={minArea} setMin={setMinArea} max={maxArea} setMax={setMaxArea} />

                    <Stack
                        direction={{ xs: "row", sm: "row" }}
                        spacing={1}
                        justifyContent="space-evenly"
                        alignContent="center"
                    //sx={{ width: "100%", margin: "0 auto" }}
                    >
                        <ToggleButtonsBedroom
                            initialValue={bedrooms}
                            onChange={(newValue) => setBedrooms(newValue)}
                        />

                        <ToggleSuite
                            initialValue={suites}
                            onChange={(newValue) => setSuites(newValue)}
                        />

                        <ToggleGarage
                            initialValue={garages}
                            onChange={(newValue) => setGarages(newValue)}
                        />
                    </Stack>

                </Stack>

                <Button
                    variant="contained"
                    color="secondary"
                    endIcon={<SearchIcon />}
                    onClick={() => {
                        let query = "";
                        if (
                            typeHouse !== undefined &&
                            typeHouse !== null &&
                            typeHouse !== ""
                        ) {
                            query += `&type=${typeHouse}`;
                        }
                        if (
                            city !== undefined &&
                            city !== null &&
                            city !== ""
                        ) {
                            query += `&city=${city}`;
                        }
                        if (
                            minPrice !== undefined &&
                            minPrice !== null &&
                            minPrice !== ""
                        ) {
                            query += `&minPrice=${minPrice}`;
                        }
                        if (
                            maxPrice !== undefined &&
                            maxPrice !== null &&
                            maxPrice !== ""
                        ) {
                            query += `&maxPrice=${maxPrice}`;
                        }
                        if (
                            minArea !== undefined &&
                            minArea !== null &&
                            minArea !== ""
                        ) {
                            query += `&minArea=${minArea}`;
                        }
                        if (
                            maxArea !== undefined &&
                            maxArea !== null &&
                            maxArea !== ""
                        ) {
                            query += `&maxArea=${maxArea}`;
                        }
                        if (
                            bedrooms !== undefined &&
                            bedrooms !== null &&
                            bedrooms !== ""
                        ) {
                            query += `&bedrooms=${bedrooms}`;
                        }
                        if (
                            suites !== undefined &&
                            suites !== null &&
                            suites !== ""
                        ) {
                            query += `&suites=${suites}`;
                        }
                        if (
                            garages !== undefined &&
                            garages !== null &&
                            garages !== ""
                        ) {
                            query += `&garages=${garages}`;
                        }
                        if (
                            order !== undefined &&
                            order !== null &&
                            order !== ""
                        ) {
                            query += `&order=${order}`;
                        }
                        navigate(`/houses?model=${modelHouse}${query}`);
                        //window.location.reload();
                    }}
                >
                    Buscar
                </Button>
            </Stack>
        </Box>
    );
};

export default CompleteSearch;

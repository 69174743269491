import React from 'react';
import { HouseImage } from '../entities/house';
import api from '../services/api';

interface IQueryHouseImages {
    houseId: number;
}

const useHouseImages = ({ houseId }: IQueryHouseImages) => {
    const [data, setData] = React.useState<HouseImage[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState();

    React.useEffect(() => {
        setData([]);
        setError(undefined);
        setLoading(true);

        api.getHouseImages({ houseId: houseId })
            .then((res) => setData(res.data.data))
            .catch((err) => setError(err))
            .finally(() => setLoading(false));

    }, [houseId]);

    return {
        data,
        loading,
        error,
    }
}

export default useHouseImages;
import { Box, Stack } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { HouseImage } from "../../entities/house";
import NextIcon from "@mui/icons-material/NavigateNextRounded";
import BeforeIcon from "@mui/icons-material/NavigateBeforeRounded";

interface IQueryListHouse {
    images?: HouseImage[];
}

function SampleNextArrow(props: any) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "white" }}
            onClick={onClick}
        >
            <NextIcon
                sx={{
                    color: "silver",
                    borderRadius: "50%",
                    "&:hover": {
                        backgroundColor: "silver",
                        color: "white",
                        cursor: "pointer",
                    },
                }}
            />
        </div>
    );
}

function SamplePrevArrow(props: any) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "white" }}
            onClick={onClick}
        >
            <BeforeIcon
                sx={{
                    color: "silver",
                    borderRadius: "50%",
                    "&:hover": {
                        backgroundColor: "silver",
                        color: "white",
                        cursor: "pointer",
                    },
                }}
            />
        </div>
    );
}

export default function ListHouseImage({ images }: IQueryListHouse) {
    if (images === undefined || images === null || images.length <= 0) {
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                width: { xs: "100%", sm: "300px" },
                alignItems: "center",
                justifyContent: "center",
                background: "silver",
            }}
        />
    }
    const settings = {
        dots: false,
        fade: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };
    return (
        <Stack>
            <Slider {...settings}>
                {images!.map((image) => {
                    return (
                        <Box
                            key={`listHouseImage${image.COD_IMOVEL_ANEXO}`}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                height: { xs: "300px", sm: "200px" },
                                alignItems: "center",
                                justifyContent: "center",
                                background: "silver",
                            }}
                        >
                            <img
                                key={`${image.COD_IMOVEL_ANEXO}`}
                                src={image.URL_PEQUENA}
                                alt={image.DESCRICAO}
                                style={{
                                    height: "100%",
                                    width: "100%",
                                    objectFit: "cover",
                                }}
                                loading="lazy"
                            />
                        </Box>
                    );
                })}
            </Slider>
        </Stack>
    );
}

import {
    Box,
    Button,
    Paper,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import React, { useRef } from "react";
import ScreenHouses from "../../components/ScreenHouse";
import IconDetails from "../../components/IconDetails/icondetails";

import HouseBloc from "../../components/House/bloc";
import Maps from "../../components/Maps";
import { useParams } from "react-router-dom";
import useHouse from "../../hooks/useHouse";
import useHouseImages from "../../hooks/useHouseImages";
import Loading from "../../components/Loading/loading";
import useHouses from "../../hooks/useHouses";
import { House } from "../../entities/house";
import numbers from "../../functions/numbers";
import { useNavigate } from "react-router-dom";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import RequestContact from "../../components/RequestContact";
import ZoomImages from "../../components/House/zoomImages";
import Slider from "react-slick";

interface IQueryHouse {
    index: number;
    house: House;
}

const ScreenHousePage = function ScreenHousePage(): JSX.Element {
    const [animationState, setAnimationState] = React.useState(false);
    const [zoomImages, setZoomImages] = React.useState<number | null>(null);
    const navigate = useNavigate();
    const sliders = useRef<Slider>(null);

    const { id } = useParams();
    const { data: house, loading: loadHouse, error } = useHouse({ id: id });
    const { data: images, loading: loadImages } = useHouseImages({
        houseId: Number(id),
    });
    const { data: houseRecent, loading: loadHouseRecent } = useHouses({
        recent: true,
    });

    React.useEffect(() => {
        setAnimationState(true);
        return () => setAnimationState(false);
    }, []);

    React.useEffect(() => {
        console.log(house);
    }, [house]);

    React.useEffect(() => {
        if (zoomImages !== null) {
            new Promise(res => setTimeout(res, 500))
                .then(() => sliders.current!.slickGoTo(zoomImages));
        }
    }, [zoomImages])

    if (loadHouse) {
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                margin: "0 auto",
                mt: { xs: 0, sm: 0 },
            }}
        >
            <Stack
                direction={{
                    sm: "row",
                    xs: "column",
                }}
                spacing={3}
                alignContent="center"
                sx={{
                    width: "100%",
                    marginTop: "50px",
                }}
            >
                <Typography>Carregando...</Typography>
            </Stack>
        </Box>
    }

    if (error || (!loadHouse && house.COD_IMOVEL === -1)) {
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                margin: "0 auto",
                mt: { xs: 0, sm: 0 },
            }}
        >
            <Stack
                direction={{
                    sm: "row",
                    xs: "column",
                }}
                spacing={3}
                alignContent="center"
                sx={{
                    width: "100%",
                    marginTop: "50px",
                }}
            >
                <Typography>Não encontrado...</Typography>
            </Stack>
        </Box>
    }

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                margin: "0 auto",
                mt: { xs: 0, sm: 0 },
            }}
        >
            <Stack
                direction={{
                    sm: "row",
                    xs: "column",
                }}
                spacing={3}
                alignContent="center"
                sx={{
                    width: "100%",
                    marginTop: "50px",
                }}

            /* tudo */
            >
                <Box
                    component={Paper}
                    sx={{
                        width: { xs: "100%", sm: "70%" },
                    }}
                >
                    <Stack
                        spacing={3}
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                        }}
                    /* image, descrição, icon, map */
                    >

                        <ScreenHouses
                            key="ScreenHouses"
                            images={images}
                            sliders={sliders}
                            onClick={(index) => setZoomImages(index)} />
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: { sm: "row", xs: "column" },
                                /* alignContent: "center", */
                            }}
                        >
                            {/* Dados do imovel */}
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "100%",
                                    padding: 2,
                                }}
                            >
                                <Typography variant="overline">
                                    {house.CIDADE || "Cidade"}
                                </Typography>
                                <Typography variant="h6" color="primary.main">
                                    {house.BAIRRO || "Bairro"}
                                </Typography>
                                <Typography variant="body2">{`${house.ENDERECO}, ${house.NUMERO}`}</Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "100%",
                                    padding: 2,
                                    alignItems: { sm: "flex-end", xs: "start" },
                                }}
                            >
                                <Typography variant="h6" color="primary.main">
                                    {(house.VL_ALUGUEL || 0) > 0
                                        ? `ALUGUEL R$ ${numbers.toString(
                                            house.VL_ALUGUEL
                                        )}`
                                        : ``}
                                </Typography>
                                <Typography variant="h6" color="primary.main">
                                    {(house.VL_VENDA || 0) > 0
                                        ? `VENDA R$ ${numbers.toString(
                                            house.VL_VENDA
                                        )}`
                                        : ``}
                                </Typography>

                                {(house.VL_IPTU || 0) > 0 ? (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            margin: "1px",
                                        }}
                                    >
                                        <ReceiptOutlinedIcon
                                            sx={{
                                                color: "secondary",
                                                "&:hover": {
                                                    color: "secondary.main",
                                                },
                                            }}
                                            fontSize="small"
                                        />
                                        <Typography variant="overline">
                                            {`IPTU R$ ${numbers.toString(
                                                house.VL_IPTU
                                            )}`}
                                        </Typography>
                                    </Box>
                                ) : (
                                    <></>
                                )}

                                {(house.VL_CONDOMINIO || 0) > 0 ? (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            margin: "1px",
                                        }}
                                    >
                                        <MonetizationOnOutlinedIcon
                                            sx={{
                                                color: "secondary",
                                                "&:hover": {
                                                    color: "secondary.main",
                                                },
                                            }}
                                            fontSize="small"
                                        />
                                        <Typography variant="overline">
                                            {`Condominio R$ ${numbers.toString(
                                                house.VL_CONDOMINIO
                                            )}`}
                                        </Typography>
                                    </Box>
                                ) : (
                                    <></>
                                )}
                            </Box>
                        </Box>

                        <IconDetails key={"3"} center={true} house={house} />

                        {((house.DESCRICAO_DETALHADA_BLOB || "") !== "")
                            ? (<Box sx={{ m: "10px", p: 1 }}>
                                <Typography
                                    variant="h6"
                                    align="center"
                                    color="gray"
                                >
                                    {house.DESCRICAO_DETALHADA_BLOB}
                                </Typography>
                            </Box>)
                            : (<></>)}

                        <Maps lat={house.LATITUDE} lng={house.LONGITUDE} />
                    </Stack>
                </Box>

                <Box
                    sx={{
                        width: { xs: "100%", sm: "30%" },
                    }}
                >
                    <RequestContact id={id} />
                </Box>

            </Stack>

            <HouseBloc
                index={3}
                title="Você também pode gostar"
                houses={houseRecent}
            />

            <Loading loading={loadHouse || loadImages} />

            <ZoomImages
                open={zoomImages !== null}
                images={images}
                sliders={sliders}
                onClose={() => setZoomImages(null)} />
        </Box>
    );
};
export default ScreenHousePage;

import { ToggleButtonGroup, ToggleButton, Box, Typography, Stack } from "@mui/material";
import * as React from "react";
import BathtubRoundedIcon from "@mui/icons-material/BathtubRounded";


interface IQueryTypeSuite {
    initialValue: string | null | undefined;
    onChange: (newValue: string | null) => void;
}

export default function ToggleSuite({ initialValue, onChange }: IQueryTypeSuite) {
    const [value, setValue] = React.useState<string | null | undefined>(
        initialValue
    );

    const handleAlignment = (
        event: React.MouseEvent<HTMLElement>,
        newValue: string | null
    ) => {
        setValue(newValue);
        if (onChange !== null) {
            onChange(newValue);
        }
    };

    return (
        <Box

            sx={{
                display: "flex",
                flexDirection: "column",


            }}
        >
            <Stack
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: { sm: "center", xs: "center" },
                }}
            >
                <BathtubRoundedIcon
                    fontSize="small"
                    sx={{
                        border: "1px solid silver",
                        m: 1,
                        p: 1,
                        borderRadius: "50%",
                        color: "gray",
                        "&:hover": {
                            backgroundColor: "silver",
                            color: "white",
                        },
                    }}
                />
                <Typography>Suites</Typography>
            </Stack>
            <Box>
                <ToggleButtonGroup
                    orientation="horizontal"
                    value={value}
                    exclusive
                    onChange={handleAlignment}
                    size="small"
                    color="primary"
                    aria-label="Suítes :"
                >
                    <ToggleButton value="1" aria-label="left aligned" color="secondary">
                        1
                    </ToggleButton>
                    <ToggleButton value="2" aria-label="centered" color="secondary">
                        2
                    </ToggleButton>
                    <ToggleButton value="3" aria-label="centered" color="secondary">
                        3
                    </ToggleButton>
                    <ToggleButton value="4" aria-label="centered" color="secondary">
                        4+
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>
        </Box>
    );
}

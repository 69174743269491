import React from 'react';
import { House } from '../entities/house';
import api from '../services/api';

const useHouse = ({ id }: any) => {
    const [data, setData] = React.useState<House>({ COD_IMOVEL: -1 });
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState();

    const exec = () => {
        setData({ COD_IMOVEL: -1 });
        setError(undefined);
        setLoading(true);

        api.getHouse(id)
            .then((res) => {
                if (res.data.data[0] !== undefined) {
                    setData(res.data.data[0]);
                }
            })
            .catch((err) => setError(err))
            .finally(() => setLoading(false));
    }

    React.useEffect(() => {
        exec();
    }, [id]);

    return {
        data,
        loading,
        error,
    }
}

export default useHouse;
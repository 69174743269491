import { Stack, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";

export default function RangePrice({ min, setMin, max, setMax }: any) {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
            }}
        >
            <Stack
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: { sm: "center", xs: "center" },
                }}
            >
                <CurrencyExchangeIcon
                    fontSize="small"
                    sx={{
                        border: "1px solid silver",
                        m: 1,
                        p: 1,
                        borderRadius: "50%",
                        color: "gray",
                        "&:hover": {
                            backgroundColor: "silver",
                            color: "white",
                        },
                    }}
                />
                <Typography>Preço</Typography>
            </Stack>
            <Stack
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: { sm: "center", xs: "center" },
                }}
            >
                <TextField
                    //sx={{ padding: 1, width: "100%" }}
                    value={min || ""}
                    variant="outlined"
                    size="small"
                    placeholder="Mínimo R$"
                    onChange={(e) => setMin(e.target.value)}
                />
                <Box sx={{ width: 10 }} />
                <TextField
                    //sx={{ padding: 1, width: "100%" }}
                    value={max || ""}
                    variant="outlined"
                    size="small"
                    placeholder="Máximo R$"
                    onChange={(e) => setMax(e.target.value)}
                />
            </Stack>
        </Box>
    );
}

import React from "react";
import { Box, Paper, Stack, Typography, Slide } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useLocalStorage from "../../hooks/useLocalStorage";

const CompanyPage = function CompanyPage(): JSX.Element {
    const [animationState, setAnimationState] = React.useState(false);
    const [company,] = useLocalStorage("imobios:company", { COD_EMPRESA: -1 });

    React.useEffect(() => {
        setAnimationState(true);
        return () => setAnimationState(false);
    }, []);

    return (
        <Slide direction="up" in={animationState} mountOnEnter unmountOnExit>
            <Box
                sx={{
                    width: "100%",
                    margin: "0 auto",
                    /* height: "70vh", */
                    mt: 5,
                }}
            >
                <Stack
                    direction="column"
                    justifyContent="space-around"
                    alignContent="center"
                    alignItems="center"
                    sx={{ width: "100%", margin: "0 auto" }}
                >
                    <Box>
                        <Typography variant="h4" align="center" color="gray">
                            Sobre nós
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            height: "4px",
                            width: "200px",
                            backgroundColor: "secondary.main",
                            m: 1,
                        }}
                    />
                    <Box sx={{ mt: 5, }}>
                        <Typography
                            variant="caption"
                            color="gray"
                            fontSize={16}
                        >
                            {company.SITE_SOBRE_BLOB}
                        </Typography>
                    </Box>
                </Stack>
            </Box>
        </Slide>
    );
};
export default CompanyPage;

import React from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import useLocalStorage from "../../hooks/useLocalStorage";

interface IGoogleMapsApi {
    key?: string;
    lat: number | null | undefined;
    lng: number | null | undefined;
}

const GooleMapsApi = ({ key, lat, lng }: IGoogleMapsApi) => {
    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: key || "",
    });

    return (
        <Box
            sx={{
                width: "md",
                height: "300px",
                background: "silver",
            }}
        >
            {isLoaded ? (
                <GoogleMap
                    mapContainerStyle={{ height: "300px" }}
                    center={{
                        //lat: -23.5489,
                        //lng: -46.6388,
                        lat: lat || 0,
                        lng: lng || 0,
                    }}
                    zoom={15}
                />
            ) : (
                <Box>
                    <Typography>Carregando endereço...</Typography>
                </Box>
            )}
        </Box>
    );
}

export default function Maps({ lat, lng }: IGoogleMapsApi) {
    const [company,] = useLocalStorage("imobios:company", { COD_EMPRESA: -1 });

    if (company.GOOGLE_MAPS_KEY === undefined ||
        company.GOOGLE_MAPS_KEY === null ||
        company.GOOGLE_MAPS_KEY === "") {
        return (<></>);
    }

    if (lat === undefined || lat === null || lat === 0 ||
        lng === undefined || lng === null || lng === 0) {
        return (<></>);
    }

    return GooleMapsApi({
        key: company.GOOGLE_MAPS_KEY,
        lat: lat,
        lng: lng
    });
}

import React from 'react';
import { Stack } from "@mui/material";
import { Box } from "@mui/system";
import Slider from "react-slick";
import { HouseImage } from "../../entities/house";
import NextIcon from "@mui/icons-material/NavigateNextRounded";
import BeforeIcon from "@mui/icons-material/NavigateBeforeRounded";
import { useRef } from "react";

interface IQueryScreenHouses {
    images: HouseImage[];
    sliders: any;
    maxHeight?: number;
    onClick?: (index: number) => void;
}

function SampleNextArrow(props: any) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "white" }}
            onClick={onClick}
        >
            <NextIcon
                sx={{
                    color: "silver",
                    borderRadius: "50%",
                    "&:hover": {
                        backgroundColor: "silver",
                        color: "white",
                        cursor: "pointer",
                    },
                }}
            />
        </div>
    );
}

function SamplePrevArrow(props: any) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "white" }}
            onClick={onClick}
        >
            <BeforeIcon
                sx={{
                    color: "silver",
                    borderRadius: "50%",
                    "&:hover": {
                        backgroundColor: "silver",
                        color: "white",
                        cursor: "pointer",
                    },
                }}
            />
        </div>
    );
}

const ScreenHouses = ({ images, sliders, maxHeight, onClick }: IQueryScreenHouses) => {
    if (!images || images === null) {
        return (<></>);
    }

    const handleClick = (index: number) => {
        if (onClick !== undefined) {
            onClick(index);
        }

    }

    /*React.useEffect(() => { 
        return () => sliders.current!.slickGoTo(initialSlide); 
    }, [initialSlide]);*/

    const settings = {
        dots: true,
        fade: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: onClick !== undefined ? <SampleNextArrow /> : undefined,
        prevArrow: onClick !== undefined ? <SamplePrevArrow /> : undefined,
    };
    return (
        <Stack>
            <Slider {...settings}
                //asNavFor={sliders2.current!}
                ref={sliders}
            >
                {images.map((image, index) => {
                    return (
                        <Box
                            sx={{
                                display: "flex",
                                width: "100%",
                                height: onClick !== undefined
                                    ? { sm: "500px", xs: "300px" }
                                    : (maxHeight || 0) > 0
                                        ? `${maxHeight}px`
                                        : "800px",
                                maxHeight: "90%",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <img
                                key={`${image.COD_IMOVEL_ANEXO}`}
                                src={image.URL_MEDIA}
                                alt={image.DESCRICAO}
                                style={{
                                    height: (maxHeight || 0) > 0
                                        ? `${maxHeight}px`
                                        : "100%",
                                    width: "100%",
                                    objectFit: "contain",
                                }}
                                loading="eager"
                                onClick={() => {
                                    handleClick(index);
                                }}
                            />
                        </Box>
                    );
                })}
            </Slider>
            {/*<Slider
                asNavFor={sliders1.current!}
                ref={sliders2}
                slidesToShow={5}
                swipeToSlide={true}
                focusOnSelect={true}>
                {images.map((image) => {
                    return (
                        <Box
                            sx={{
                                display: "flex",
                                width: "50px",
                                height: "50px",
                                justifyContent: "center",
                            }}
                        >
                            <img
                                key={`min${image.COD_IMOVEL_ANEXO}`}
                                src={image.IMAGEM}
                                alt={image.DESCRICAO}
                                style={{
                                    height: "50px",
                                    width: "50px",
                                    objectFit: "cover",
                                }}
                                loading="lazy"
                            />
                        </Box>
                    );
                })}
            </Slider>*/}
        </Stack>
    );
}

export default ScreenHouses;
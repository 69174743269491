import React from 'react';
import { House } from '../entities/house';
import api from '../services/api';

interface IQueryHouses {
    contrast?: boolean;
    recent?: boolean;
    model?: string;
    type?: string;
    city?: string;
    minPrice?: number | undefined;
    maxPrice?: number | undefined;
    minArea?: number | undefined;
    maxArea?: number | undefined;
    bedrooms?: number | undefined;
    suites?: number | undefined;
    garages?: number | undefined;
    order?: string | undefined | null;
}

const useHouses = ({ contrast, recent, model, type, city,
    minPrice, maxPrice, minArea, maxArea, bedrooms, suites, garages, order }: IQueryHouses) => {
    const [data, setData] = React.useState<House[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState();

    const processData = async (data: House[]) => {
        //console.log(data);
        try {
            await Promise.all(data.map(async (item) => {
                if (item.COD_IMOVEL !== undefined && item.COD_IMOVEL > 0) {
                    await api.getHouseImages({ houseId: item.COD_IMOVEL })
                        .then((res) => item.images = res.data.data);
                }
            }));
            setData(data);
        } finally {
            setLoading(false);
        }
    }

    const processError = (err: any) => {
        setError(err);
        setLoading(false);
    }

    const exec = () => {
        setData([]);
        setError(undefined);
        setLoading(true);

        if (contrast) {
            api.getHouseContrast()
                .then((res) => processData(res.data.data))
                .catch((err) => processError(err));
        } else if (recent) {
            api.getHouseRecent()
                .then((res) => processData(res.data.data))
                .catch((err) => processError(err));
        } else if (model || type || city) {
            api.getHousesQuery({
                model: model, type: type, city: city,
                minPrice: minPrice, maxPrice: maxPrice, minArea: minArea, maxArea: maxArea,
                bedrooms: bedrooms, suites: suites, garages: garages, order: order,
            })
                .then((res) => processData(res.data.data))
                .catch((err) => processError(err));
        } else {
            api.getHouses()
                .then((res) => processData(res.data.data))
                .catch((err) => processError(err));
        }
    }

    React.useEffect(() => {
        exec();
    }, [contrast, recent, model, type, city,
        minPrice, maxPrice, minArea, maxArea, bedrooms, suites, garages, order]);

    return {
        data,
        loading,
        error,
    }
}

export default useHouses;
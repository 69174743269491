import { Box, Container, Stack, Typography } from "@mui/material";
import { House } from "../../entities/house";
import ImovelSlick from "../ImovelSlick";

interface IQueryHouseBloc {
    index: number;
    title: string;
    houses: House[];
}

export default function HouseBloc({ index, title, houses }: IQueryHouseBloc) {
    if ((houses === null) || (houses.length <= 0)) {
        return (<></>);
    }
    return (
        <Box>
            <Stack
                justifyContent="space-around"
                alignContent="center"
                sx={{ width: "100%", margin: "0 auto", py: { sm: 3, xs: 3 } }}
            >
                <Stack
                    direction="column"
                    justifyContent="space-around"
                    alignContent="center"
                    alignItems="center"
                    sx={{ width: "100%", margin: "0 auto" }}
                >
                    <Typography variant="h5">{title}</Typography>
                    <Box
                        sx={{
                            height: "4px",
                            width: "200px",
                            backgroundColor: "secondary.main",
                        }}
                    />
                </Stack>
            </Stack>
            <ImovelSlick index={index} houses={houses} />
            <Box sx={{ height: "20px" }} />
        </Box>
    );
}

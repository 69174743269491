function toString(value?: number): string {
    if (!value || value === null) {
        return "";
    }
    return new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, }).format(value);
}

function random(max: number, min?: number): number {
    min = min || 0;
    const rand = min + Math.random() * (max - min);
    return rand;
}

function randomInt(max: number, min?: number): number {
    min = min || 0;
    const rand = min + Math.floor(Math.random() * (max - min));
    return rand;
}

function onlyNumbers(value?: string | null): string {
    if (value === undefined || value === null) {
        return "";
    }
    return value.replace(/\D/g, '');
}

const numbers = {
    toString,
    random,
    randomInt,
    onlyNumbers,
}

export default numbers;
import React, { useLayoutEffect } from "react";
import { BrowserRouter, Routes, Route, useNavigate, useLocation } from "react-router-dom";
import ScreenHouses from "../components/ScreenHouse";
import MainLayoute from "../layoutes/main.layout";
import CompanyPage from "../pages/company";
import HomePage from "../pages/home";
import ListHousePage from "../pages/listhouse";
import ScreenHousePage from "../pages/house";

const Wrapper = ({ children }: any) => {
    const location = useLocation();
    useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
};

const MainRoutes: React.FC = () => {
    return (
        <BrowserRouter>
            <Wrapper>
                <Routes>
                    <Route path="/" element={<MainLayoute />}>
                        <Route index element={<HomePage />} />
                        <Route path="company" element={<CompanyPage />} />
                        <Route path="house/:id" element={<ScreenHousePage />} />
                        <Route path="houses" element={<ListHousePage />} />
                    </Route>
                </Routes>
            </Wrapper>
        </BrowserRouter>
    );
};

export default MainRoutes;

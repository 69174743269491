import { useRef } from 'react';
import { Backdrop, Box, Button, Container, Stack } from "@mui/material";
import ScreenHouses from "../ScreenHouse";
import CloseIcon from "@mui/icons-material/Close";
import useWindowDimensions from '../../hooks/useWindowsDimensions';

const ZoomImages = ({ open, images, sliders, onClose }: any) => {
    const { height, width } = useWindowDimensions();
    const windowsHeight = (height || 100) - 100;
    console.log(windowsHeight);
    return (
        <Backdrop
            sx={{
                bgcolor: "#000",
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={open}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "60%",
                    //height: "96%",
                    maxHeight: `${height}px`,
                    margin: "0 auto",
                    mt: { xs: 0, sm: 0 },
                }}
            >
                <Stack
                    display="block"
                    direction="row"
                    alignContent="end"
                    alignItems="end"
                    textAlign="end">
                    <Button
                        variant="outlined"
                        color="secondary"
                        startIcon={<CloseIcon />}
                        onClick={onClose}>Fechar</Button>
                </Stack>
                <ScreenHouses
                    key="ScreenHousesZoom"
                    maxHeight={windowsHeight}
                    images={images}
                    sliders={sliders} />
            </Box>
        </Backdrop>
    );
}

export default ZoomImages;
import { Box, Button, Paper, Stack, TextField, Typography } from "@mui/material";
import ListHouse from "../../components/ListHouse";
import useQueryParams from "../../hooks/useQueryParams";
import useHouses from "../../hooks/useHouses";
import React from "react";
import { useNavigate } from "react-router-dom";
import CompleteSearch from "../../components/Search/CompleteSearch";

const ListHousePage = function ListHousePage(): JSX.Element {
    const [animationState, setAnimationState] = React.useState(false);
    const navigate = useNavigate();

    const query = useQueryParams();
    const model = query.get("model") || "rent";
    const type = query.get("type");
    const cityName = query.get("city");
    const order = query.get("order");

    const sMinPrice = query.get("minPrice");
    const sMaxPrice = query.get("maxPrice");
    const sMinArea = query.get("minArea");
    const sMaxArea = query.get("maxArea");
    const sBedrooms = query.get("bedrooms");
    const sSuites = query.get("suites");
    const sGarages = query.get("garages");

    let minPrice, maxPrice, minArea, maxArea, bedrooms, suites, garages;

    if (sMinPrice !== undefined && sMinPrice !== null && sMinPrice !== "") {
        minPrice = Number(sMinPrice);
    }
    if (sMaxPrice !== undefined && sMaxPrice !== null && sMaxPrice !== "") {
        maxPrice = Number(sMaxPrice);
    }
    if (sMinArea !== undefined && sMinArea !== null && sMinArea !== "") {
        minArea = Number(sMinArea);
    }
    if (sMaxArea !== undefined && sMaxArea !== null && sMaxArea !== "") {
        maxArea = Number(sMaxArea);
    }
    if (sBedrooms !== undefined && sBedrooms !== null && sBedrooms !== "") {
        bedrooms = Number(sBedrooms);
    }
    if (sSuites !== undefined && sSuites !== null && sSuites !== "") {
        suites = Number(sSuites);
    }
    if (sGarages !== undefined && sGarages !== null && sGarages !== "") {
        garages = Number(sGarages);
    }

    const { data: houses, loading, error } = useHouses({
        model: model,
        type: type || "",
        city: cityName || "",
        minPrice: minPrice,
        maxPrice: maxPrice,
        minArea: minArea,
        maxArea: maxArea,
        bedrooms: bedrooms,
        suites: suites,
        garages: garages,
        order: order,
    });

    React.useEffect(() => {
        setAnimationState(true);
        return () => setAnimationState(false);
    }, []);

    return (
        <Box>
            <CompleteSearch
                model={model}
                type={type}
                cityName={cityName}
            />
            <Box sx={{ height: "20px" }} />
            <ListHouse loading={loading} houses={houses} />
        </Box>
    );
};

export default ListHousePage;

import { CircularProgress } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { House } from "../../entities/house";

interface IQueryHouse {
    index: number;
    loading: boolean;
    house: House | undefined;
}

export default function HouseImageItem({ index, loading, house }: IQueryHouse) {
    const navigate = useNavigate();

    if (loading) {
        return (<CircularProgress color="inherit" />);
    }
    if (!house || house === null || !house.images || house.images === null || house.images.length <= 0) {
        return (<></>);
    }
    const image = house.images[0];
    /*if (images.length > 1) {
        const index = numbers.randomInt(images.length);
        image = images[index];
    }*/
    //console.log(image);
    return (
        <img key={`houseImg${index}${image.COD_IMOVEL_ANEXO}`}
            src={image.URL_PEQUENA}
            alt={`${index} - ${image.DESCRICAO}`}
            style={{
                height: "100%",
                width: "100%",
                cursor: 'pointer',
                objectFit: "cover"
            }}
            loading="eager" />
    );
}
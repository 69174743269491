function isNullOrEmpty(value?: string | undefined | null): boolean {
    return value === undefined || value === null || value.trim() === "";
}

function isNotNullOrEmpty(value?: string | undefined | null): boolean {
    return !isNullOrEmpty(value);
}

const strings = {
    isNullOrEmpty,
    isNotNullOrEmpty,
}

export default strings;
import React from "react";
import {
  AppBar,
  Button,
  Container,
  Fab,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Outlet, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import PhoneIcon from "@mui/icons-material/PhoneAndroidRounded";
import Footer from "../components/Footer/footer";
import useLocalStorage from "../hooks/useLocalStorage";
import browser from "../functions/browser";
import numbers from "../functions/numbers";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { green } from "@mui/material/colors";

//const drawerWidth = 240;
const pages = ["A Empresa", "Contato"];
const settings = ["Profile", "Account", "Dashboard", "Logout"];

const MainLayoute: React.FC = (props: any) => {
  const auth = true;
  const navigate = useNavigate();
  const [company,] = useLocalStorage("imobios:company", { COD_EMPRESA: -1 });

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const openWhatsApp = () => {
    const cell = numbers.onlyNumbers(company.CELULAR);
    if (cell !== "") {
      browser.openInNewTab(`https://wa.me/55${cell}`);
    }
  }

  const handleCloseNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(null);
    if (event.currentTarget.textContent === pages[0]) {
      navigate("/company");
    } else if (event.currentTarget.textContent === pages[1]) {
      openWhatsApp();
    }
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  React.useEffect(() => {
    if (!auth) {
      navigate("/");
    }
  }, [auth]);

  if (auth) {
    return (
      <Box
        sx={{
          //display: "flex",
          width: "100%",
          //flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <AppBar position="fixed">
          <Container maxWidth="lg">
            <Toolbar disableGutters>
              {/*<AdbIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} />
              <Typography
                variant="h6"
                noWrap
                component="a"
                href="/"
                sx={{
                  mr: 2,
                  display: { xs: "none", md: "flex" },
                  fontFamily: "monospace",
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                LOGO
              </Typography>*/}
              <Box sx={{ display: { xs: "none", md: "block" }, mr: 1, height: "70px" }}>
                <img src={company.LOGO} alt="logo" height="70px" onClick={() => navigate("/")} />
              </Box>

              <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: "block", md: "none" },
                  }}
                >
                  {pages.map((page) => (
                    <MenuItem key={page} onClick={handleCloseNavMenu}>
                      <Typography textAlign="center">{page}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
              {/*<AdbIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} />
              <Typography
                variant="h5"
                noWrap
                component="a"
                href=""
                sx={{
                  mr: 2,
                  display: { xs: "flex", md: "none" },
                  flexGrow: 1,
                  fontFamily: "monospace",
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                LOGO
              </Typography>*/}

              <Box sx={{ display: { xs: "block", md: "none" }, mr: 1, height: "55px" }}>
                <img src={company.LOGO} alt="logo" height="55px" onClick={() => navigate("/")} />
              </Box>
              <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                {pages.map((page) => (
                  <Button
                    key={page}
                    onClick={handleCloseNavMenu}
                    sx={{ my: 2, color: "white", display: "block" }}
                  >
                    {page}
                  </Button>
                ))}
              </Box>

              <PhoneIcon sx={{ display: { xs: "none", md: "block" }, mr: 1 }} />
              <Stack
                direction={"column"}
                sx={{ display: { xs: "none", md: "block" }, mr: 1 }}>
                <Typography
                  noWrap
                  sx={{
                    //mr: 2,
                    display: { xs: "none", md: "block" },
                    //fontFamily: "monospace",
                    fontWeight: 500,
                    //letterSpacing: ".2rem",
                    fontSize: 12,
                    color: "inherited",
                    textDecoration: "none",
                  }}
                >
                  Ligue agora
                </Typography>
                <Typography
                  ///variant="h6"
                  noWrap
                  //component="a"
                  //href="/"
                  sx={{
                    //mr: 2,
                    display: { xs: "none", md: "block" },
                    //fontFamily: "monospace",
                    //fontWeight: 500,
                    //letterSpacing: ".2rem",
                    color: "inherit",
                    textDecoration: "none",
                  }}
                >
                  {company.FONE || company.CELULAR || "Telefone"}
                </Typography>
              </Stack>

              <PhoneIcon sx={{ display: { xs: "block", md: "none" }, mr: 1 }} />
              <Stack
                direction={"column"}
                sx={{ display: { xs: "block", md: "none" }, mr: 1 }}>
                <Typography
                  noWrap
                  sx={{
                    //mr: 2,
                    display: { xs: "block", md: "none" },
                    //fontFamily: "monospace",
                    fontWeight: 500,
                    //letterSpacing: ".2rem",
                    fontSize: 12,
                    color: "inherited",
                    textDecoration: "none",
                  }}
                >
                  Ligue agora
                </Typography>
                <Typography
                  ///variant="h6"
                  noWrap
                  //component="a"
                  //href="/"
                  sx={{
                    //mr: 2,
                    display: { xs: "flex", md: "none" },
                    //fontFamily: "monospace",
                    //fontWeight: 500,
                    //letterSpacing: ".2rem",
                    color: "inherit",
                    textDecoration: "none",
                  }}
                >
                  {company.FONE || company.CELULAR || "Telefone"}
                </Typography>
              </Stack>

              {/*<Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar
                      alt="Remy Sharp"
                      src="/static/images/avatar/2.jpg"
                    />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem key={setting} onClick={handleCloseUserMenu}>
                      <Typography textAlign="center">{setting}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
                  </Box>*/}
            </Toolbar>
          </Container>
        </AppBar>
        {/*<Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, xs: "100%" }}
          aria-label="Menu"
        >
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            open
          >
            <Box
              component="img"
              alt="Ramo esteendido"
              src="http://www.ramoestendido.com.br/wp-content/uploads/2014/02/logo.png"
              sx={{
                margin: "0 auto",
                width: "100%",
                height: "auto",
              }}
            />
            <Divider />
            <ListDrawer />
          </Drawer>
            </Box>*/}
        <Container maxWidth="lg">
          <Stack
            component="main"
            direction="column"
            sx={{
              flexGrow: 1,
              py: { sm: 10, xs: 8 },
              px: { sm: 0, xs: 0 },
              //width: { sm: `calc(100% - ${drawerWidth}px)`, xs: "100%" },
              //width: { sm: "60%", xs: "100%" },
              flexDirection: "column",
            }}
          >
            <Outlet />

          </Stack>
          <Fab
            sx={{
              position: 'fixed',
              bottom: 16,
              right: 16,
              color: 'common.white',
              bgcolor: green[500],
              '&:hover': {
                bgcolor: green[600],
              },
            }}
            aria-label="WhatsApp"
            color="inherit"
            onClick={openWhatsApp}
          >
            <WhatsAppIcon />
          </Fab>
        </Container>
        <Footer />
      </Box >

    );
  } else {
    return (
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <Outlet />
      </Box>
    );
  }
};

export default MainLayoute;

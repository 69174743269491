import {
    Box,
    Container,
    Grid,
    Button,
    TextField,
    Typography,
    Link,
    Divider,
    Stack,
    Paper,
    InputBase,
    Snackbar,
    Alert,
} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import EmailIcon from "@mui/icons-material/Email";
import SendIcon from "@mui/icons-material/Send";
import { useForm } from "react-hook-form";
import { useState } from "react";
import methods from "../../services/api";
import Loading from "../Loading/loading";
import MainLayoute from "../../layoutes/main.layout";
import numbers from "../../functions/numbers";
import useLocalStorage from "../../hooks/useLocalStorage";
import browser from "../../functions/browser";
import { useNavigate } from "react-router-dom";
import strings from "../../functions/strings";
import components from "../../functions/components";

export default function Footer() {
    const { register, handleSubmit, reset } = useForm();
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState<string | undefined | null>();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const [company] = useLocalStorage("imobios:company", { COD_EMPRESA: -1 });

    const Submit = (data: any) => {
        if (data.email === undefined || data.email === null || data.email === "") {
            setError("E-mail obrigatório!");
            return;
        }

        setSuccess(false);
        setError(undefined);
        setLoading(true);
        methods
            .post("/news-letter", data)
            .then((res) => {
                setSuccess(true);
                reset();
            })
            .catch((err) => setError(err.data.data.error))
            .finally(() => setLoading(false));
    };

    return (
        <footer>
            <Box
                bgcolor="primary.main"
                sx={{
                    display: "flex",
                    flexDirection: "center",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    margin: "0 auto",
                    mt: { xs: 0, sm: 0 },
                    color: "white",
                }}
            >
                <Container>
                    <Stack
                        component="form"
                        onSubmit={handleSubmit(Submit)}
                        direction={{ xs: "column", sm: "row" }}
                        spacing={2}
                        justifyContent="space-between"
                        justifyItems="center"
                        sx={{
                            width: { xs: "100%", sm: "100%" },
                            py: { sm: 2, xs: 2 },
                            px: { sm: 0, xs: 0 },
                        }}
                    >
                        <Typography variant="h5" sx={{ m: 1 }}>
                            Deixe seu email para receber novidades!
                        </Typography>
                        <Paper
                            sx={{
                                p: "2px 4px",
                                display: "flex",
                                alignItems: "center",
                                width: { sm: 400, sx: "100%" },
                            }}
                        >
                            <EmailIcon color="secondary" />
                            <InputBase
                                {...register("email")}
                                sx={{ ml: 1, flex: 1 }}
                                placeholder="Digite aqui o seu e-mail"
                                inputProps={{
                                    "aria-label": "search google maps",
                                }}
                            />
                        </Paper>
                        <Button
                            type="submit"
                            variant="contained"
                            endIcon={<SendIcon />}
                            sx={{ m: 2, color: "white" }}
                            color="secondary"
                        >
                            Cadastrar
                        </Button>
                    </Stack>
                    {/*  <Box
                        sx={{
                            display: "flex",
                            flexDirection: "center",
                            justifyContent: "center",
                            alignItems: "center",
                            margin: "30px",
                        }}
                    >
                        <Grid>
                            <img
                                src={company.LOGO}
                                alt="logo"
                                onClick={() => navigate("/")}
                            />
                        </Grid> 
                    </Box> */}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: 2,
                        }}
                    >
                        <Box sx={{ flexDirection: "column", m: 1 }}>
                            {components.render(
                                strings.isNotNullOrEmpty(company.URL_FACEBOOK),
                                <Link href={company.URL_FACEBOOK} sx={{ m: 1 }}>
                                    <FacebookIcon
                                        sx={{
                                            color: "white",
                                            "&:hover": {
                                                color: "secondary.main",
                                            },
                                        }}
                                        fontSize="large"
                                    />
                                </Link>
                            )}
                            {components.render(
                                strings.isNotNullOrEmpty(company.URL_INSTAGRAM),
                                <Link href={company.URL_INSTAGRAM} sx={{ m: 1 }}>
                                    <InstagramIcon
                                        sx={{
                                            color: "white",
                                            "&:hover": {
                                                color: "secondary.main",
                                            },
                                        }}
                                        fontSize="large"
                                    />
                                </Link>
                            )}
                            {components.render(
                                strings.isNotNullOrEmpty(company.URL_YOUTUBE),
                                <Link href={company.URL_YOUTUBE} sx={{ m: 1 }}>
                                    <YouTubeIcon
                                        sx={{
                                            color: "white",
                                            "&:hover": {
                                                color: "secondary.main",
                                            },
                                        }}
                                        fontSize="large"
                                    />
                                </Link>
                            )}
                            {components.render(
                                strings.isNotNullOrEmpty(company.EMAIL),
                                <Link href={`mailto:${company.EMAIL}`} sx={{ m: 1 }}>
                                    <EmailIcon
                                        sx={{
                                            color: "white",
                                            "&:hover": {
                                                color: "secondary.main",
                                            },
                                        }}
                                        fontSize="large"
                                    />
                                </Link>
                            )}
                        </Box>
                        <Divider />
                        <Box sx={{ m: 2 }}>
                            <Grid container spacing={{ xs: 2, md: 3 }} direction="row">
                                <Grid item>
                                    <Link href="/company" underline="hover" color="white">
                                        EMPRESA{" "}
                                    </Link>
                                </Grid>

                                <Grid item>
                                    <Link
                                        href="#"
                                        underline="hover"
                                        color="white"
                                        onClick={() => {
                                            const cell = numbers.onlyNumbers(company.CELULAR);
                                            if (cell !== "") {
                                                browser.openInNewTab(`https://wa.me/55${cell}`);
                                            }
                                        }}
                                    >
                                        CONTATO{" "}
                                    </Link>
                                </Grid>

                                <Grid item>
                                    <Link href="/houses?model=rent" underline="hover" color="white">
                                        ALUGAR{" "}
                                    </Link>
                                </Grid>

                                <Grid item>
                                    <Link href="/houses?model=purchase" underline="hover" color="white">
                                        COMPRAR
                                    </Link>
                                </Grid>

                                {/*  <Grid item>
                  <Link href="#" underline="hover" color="white">
                    DÚVIDAS FREQUENTES
                  </Link>
                </Grid> */}
                            </Grid>
                        </Box>

                        <Typography variant="body2">
                            Versatile Imóveis - Rua Armando Sales, 142 - Centro, Osvaldo Cruz - SP, 17700-000 -
                            imoveisversatile@gmail.com
                        </Typography>

                        <Typography variant="h6">CRECI 037931J</Typography>

                        <Typography variant="body2">Copyright © 2022 - Todos os direitos reservados.</Typography>
                    </Box>
                </Container>
            </Box>
            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={success} autoHideDuration={2000}>
                <Alert severity="success" sx={{ width: "100%" }}>
                    E-mail enviado com sucesso
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={error !== undefined && error !== null && error !== ""}
                autoHideDuration={3000}
            >
                <Alert severity="error" sx={{ width: "100%" }}>
                    {error}
                </Alert>
            </Snackbar>
            <Loading loading={loading} />
        </footer>
    );
}

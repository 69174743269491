import { Box, Typography } from "@mui/material";
import HotelRoundedIcon from "@mui/icons-material/HotelRounded";
import BathtubRoundedIcon from "@mui/icons-material/BathtubRounded";
import StraightenRoundedIcon from "@mui/icons-material/StraightenRounded";
import DirectionsCarRoundedIcon from "@mui/icons-material/DirectionsCarRounded";
import { House } from "../../entities/house";

interface IQueryHouse {
    center: boolean;
    house?: House;
}

export default function IconDetails({ center, house }: IQueryHouse) {
    if (!house) {
        return (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "center",
                    alignItems: center ? "center" : "start",
                    justifyContent: center ? "space-evenly" : "start",
                    color: "silver",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <HotelRoundedIcon
                        sx={{
                            border: "1px solid silver",
                            p: 1,
                            borderRadius: "50%",
                            "&:hover": {
                                backgroundColor: "silver",
                                color: "white",
                            },
                        }}
                    />
                    <Typography variant="caption" color="silver" align="center">
                        0 Quartos
                    </Typography>
                </Box>
                <Box
                    sx={{
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <BathtubRoundedIcon
                        sx={{
                            border: "1px solid silver",
                            p: 1,
                            borderRadius: "50%",
                            "&:hover": {
                                backgroundColor: "silver",
                                color: "white",
                            },
                        }}
                    />
                    <Typography variant="caption" color="silver" align="center">
                        0 Suítes
                    </Typography>
                </Box>
                <Box
                    sx={{
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <StraightenRoundedIcon
                        sx={{
                            border: "1px solid silver",
                            p: 1,
                            borderRadius: "50%",
                            "&:hover": {
                                backgroundColor: "silver",
                                color: "white",
                            },
                        }}
                    />
                    <Typography variant="caption" color="silver" align="center">
                        0 metros
                    </Typography>
                </Box>
                <Box
                    sx={{
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <DirectionsCarRoundedIcon
                        sx={{
                            border: "1px solid silver",
                            p: 1,
                            borderRadius: "50%",
                            "&:hover": {
                                backgroundColor: "silver",
                                color: "white",
                            },
                        }}
                    />
                    <Typography variant="caption" color="silver" align="center">
                        0 Vagas
                    </Typography>
                </Box>
            </Box>
        );
    }
    if (((house.NO_QUARTOS || 0) === 0) &&
        ((house.NO_SUITES || 0) === 0) &&
        ((house.AREA_CONSTRUIDA || 0) === 0) &&
        ((house.NO_VAGAS || 0) === 0)) {
        return (<></>);
    }
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "center",
                alignItems: center ? "center" : "start",
                justifyContent: center ? "space-evenly" : "start",
                color: "silver",
            }}
        >
            {(house.NO_QUARTOS || 0) > 0 ? (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: 1,
                    }}
                >
                    <HotelRoundedIcon
                        sx={{
                            border: "1px solid silver",
                            p: 1,
                            borderRadius: "50%",
                            "&:hover": {
                                backgroundColor: "silver",
                                color: "white",
                            },
                        }}
                    />
                    <Typography variant="caption" color="silver" align="center">
                        {`${house.NO_QUARTOS} Quartos`}
                    </Typography>
                </Box>
            ) : (
                <></>
            )}
            {(house.NO_SUITES || 0) > 0 ? (
                <Box
                    sx={{
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        margin: 1,
                    }}
                >
                    <BathtubRoundedIcon
                        sx={{
                            border: "1px solid silver",
                            p: 1,
                            borderRadius: "50%",
                            "&:hover": {
                                backgroundColor: "silver",
                                color: "white",
                            },
                        }}
                    />
                    <Typography variant="caption" color="silver" align="center">
                        {`${house.NO_SUITES} Suítes`}
                    </Typography>
                </Box>
            ) : (
                <></>
            )}
            {(house.AREA_CONSTRUIDA || 0) > 0 ? (
                <Box
                    sx={{
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        margin: 1,
                    }}
                >
                    <StraightenRoundedIcon
                        sx={{
                            border: "1px solid silver",
                            p: 1,
                            borderRadius: "50%",
                            "&:hover": {
                                backgroundColor: "silver",
                                color: "white",
                            },
                        }}
                    />
                    <Typography variant="caption" color="silver" align="center">
                        {`${house.AREA_CONSTRUIDA} metros`}
                    </Typography>
                </Box>
            ) : (
                <></>
            )}
            {(house.NO_VAGAS || 0) > 0 ? (
                <Box
                    sx={{
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        margin: 1,
                    }}
                >
                    <DirectionsCarRoundedIcon
                        sx={{
                            border: "1px solid silver",
                            p: 1,
                            borderRadius: "50%",
                            "&:hover": {
                                backgroundColor: "silver",
                                color: "white",
                            },
                        }}
                    />
                    <Typography variant="caption" color="silver" align="center">
                        {`${house.NO_VAGAS} Vagas`}
                    </Typography>
                </Box>
            ) : (
                <></>
            )}
        </Box>
    );
}

import { Stack, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import StraightenRoundedIcon from "@mui/icons-material/StraightenRounded";

export default function RangeArea({ min, setMin, max, setMax }: any) {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
            }}
        >
            <Stack
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: { sm: "center", xs: "center" },
                }}
            >
                <StraightenRoundedIcon
                    fontSize="small"
                    sx={{
                        border: "1px solid silver",
                        m: 1,
                        p: 1,
                        borderRadius: "50%",
                        color: "gray",
                        "&:hover": {
                            backgroundColor: "silver",
                            color: "white",
                        },
                    }}
                />
                <Typography>Area M²</Typography>
            </Stack>
            <Stack
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: { sm: "center", xs: "center" },
                }}
            >
                <TextField
                    //sx={{ width: "90%" }}
                    value={min || ""}
                    variant="outlined"
                    size="small"
                    placeholder="Mínimo m²"
                    onChange={(e) => setMin(e.target.value)}
                />
                <Box sx={{ width: 10 }} />
                <TextField
                    //sx={{ width: "90%" }}
                    value={max || ""}
                    variant="outlined"
                    size="small"
                    placeholder="Máximo m²"
                    onChange={(e) => setMax(e.target.value)}
                />
            </Stack>
        </Box>
    );
}

import { Box, CircularProgress, Container, Stack, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/system";
import useCompany from "./hooks/useCompany";
import MainRoutes from "./routes/routes";
import useApiTheme from "./theme/api.theme";
import lightTheme from "./theme/light.theme";

function App() {
  const { data: company, loading } = useCompany({ reload: false });
  const { theme } = useApiTheme(company);
  if (loading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        minWidth="100vw"
        color="white"
        sx={{ backgroundColor: "#2d3f60" }}>
        <CircularProgress />
        <Typography variant="h4">Carregando...</Typography>
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <MainRoutes />
    </ThemeProvider>
  );
}

export default App;
import { Box, Button, Paper, Stack, TextField, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "../../hooks/useLocalStorage";
import ModelHouse from "../ModelHouse";
import TypeHouse from "../TypeHouse";
import { useState } from "react";

interface ISimpleSearch {
    model: string | "rent" | "purchase";
    type?: string | null;
    cityName?: string | null;
}

const SimpleSearch = function SimpleSearch({ model, type, cityName }: ISimpleSearch): JSX.Element {
    const navigate = useNavigate();
    const [company,] = useLocalStorage("imobios:company", { COD_EMPRESA: -1 });
    const [modelHouse, setModelHouse] = useState<string | null>(model);
    const [typeHouse, setTypeHouse] = useState<string | null | undefined>(type);
    const [city, setCity] = useState(cityName);

    return (
        <Box
            component={Paper}
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px",
            }}
        >
            <Stack
                direction={"column"}
                spacing={2}
                justifyContent="space-around"
                alignContent="center"
                sx={{
                    width: { xs: "100%", sm: "100%" },
                    py: { sm: 2, xs: 2 },
                    px: { sm: 2, xs: 2 },
                }}
            >
                <Stack
                    direction="row"
                    justifyContent="space-around"
                    alignContent="center"
                    sx={{ width: "100%", margin: "0 auto" }}
                >
                    <Typography variant="h4">{company.NOME_FANTASIA || "Não encontrado..."}</Typography>
                </Stack>
                <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={2}
                    justifyContent="space-between"
                    alignContent="center"
                    sx={{ width: "100%", margin: "0 auto" }}
                >
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography>O que você precisa?</Typography>
                        <ModelHouse
                            initialValue={modelHouse}
                            onChange={(newValue) => setModelHouse(newValue)} />
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography>Tipos de Imóveis</Typography>
                        <TypeHouse
                            initialValue={typeHouse}
                            onChange={(newValue) => setTypeHouse(newValue)} />
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography>Localidade</Typography>
                        <TextField
                            value={city || ""}
                            variant="outlined"
                            size="small"
                            placeholder="Cidade ou Bairro"
                            onChange={(e) => setCity(e.target.value)}
                        />
                    </Box>
                    <Button
                        variant="contained"
                        color="secondary"
                        endIcon={<SearchIcon />}
                        onClick={() => {
                            let query = "";
                            if (typeHouse !== undefined &&
                                typeHouse !== null &&
                                typeHouse !== "") {
                                query += `&type=${typeHouse}`;
                            }
                            if (city !== undefined &&
                                city !== null &&
                                city !== "") {
                                query += `&city=${city}`;
                            }
                            navigate(`/houses?model=${modelHouse}${query}`);
                            //window.location.reload();
                        }}
                    >
                        Buscar
                    </Button>
                </Stack>
            </Stack>
        </Box>
    );
}

export default SimpleSearch;